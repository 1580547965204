body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Karbon_bold;
  src:url('./assets/fonts/Karbon-Bold.ttf');
}
@font-face {
  font-family: Karbon_semibold;
  src:url('./assets/fonts/Karbon-Semibold.ttf');
}
@font-face {
  font-family: Karbon_medium;
  src:url('./assets/fonts/Karbon-Medium.ttf');
}
@font-face {
  font-family: Karbon_light;
  src:url('./assets/fonts/Karbon-Light.ttf');
}

@font-face {
  font-family: Luma_semibold;
  src:url('./assets/fonts/Luma/Luma-SemiBold.ttf');
}
@font-face {
  font-family: Luma_light;
  src:url('./assets/fonts/Luma/Luma-Light.ttf');
}
@font-face {
  font-family: Luma_regular;
  src:url('./assets/fonts/Luma/Luma-Regular.ttf');
}
@font-face {
  font-family: Luma_thin;
  src:url('./assets/fonts/Luma/Luma-Thin.ttf');
}
:root {
  --primary:#3C1053;
}

.vehicle-type-list {
  display: inline-flex;
}